<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Edit Feature</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="6">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Category Feature</span>
            <v-select
              label="name"
              :options="featureCategoryOptions"
              :reduce="category => category.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.feature_category_id"
            />
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Feature Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>

<!--      <vs-col vs-w="6" class="pl-8 pt-5">-->
<!--        <div class="flex items-start flex-col sm:flex-row">-->
<!--          <img :src="url" class="rounded mr-4" width="100" height="100" />-->
<!--          <div>-->
<!--            <input-->
<!--              type="file"-->
<!--              @change="addImage"-->
<!--              class="hidden"-->
<!--              ref="update_logo_input"-->
<!--              accept="image/*"-->
<!--            />-->
<!--            <vs-button-->
<!--              @click="$refs.update_logo_input.click()"-->
<!--              class="mr-4 mb-2"-->
<!--              type="filled"-->
<!--              icon-pack="feather"-->
<!--              icon="icon-edit"-->
<!--            ></vs-button>-->
<!--            <vs-button-->
<!--              @click="removeImage"-->
<!--              type="border"-->
<!--              color="danger"-->
<!--              icon-pack="feather"-->
<!--              icon="icon-trash"-->
<!--            ></vs-button>-->
<!--          </div>-->
<!--        </div>-->
<!--        <span class="block text-sm mt-2 text-danger">{{-->
<!--          errors.first("icon")-->
<!--        }}</span>-->
<!--      </vs-col>-->
    </vs-row>
  </vx-card>
</template>

<script>
import axios from '@/axios'
import vSelect from 'vue-select'

// Store Module
import moduleFeatureManagement from "@/store/feature-management/moduleFeatureManagement.js";

export default {
  name: "FeatureEdit",

  metaInfo: {
    title: "Edit Feature",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect
  },

  data: () => ({
    payload: {
      feature_category_id: '',
      name: ""
    },
    url: null,
    loading: true,

    // Vselect options
    featureCategoryOptions: []
  }),

  watch: {
    url(url) {
      if (this.url == "features/default.png") {
        this.url = "https://via.placeholder.com/100";
      } else {
        this.url = url;
      }
    }
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    }
  },

  methods: {
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.icon = file;
    },

    removeImage() {
      this.url = null;
      this.payload.icon = "";
    },

    getFeatureCategories() {
      // TODO: error: /v1/facility-categories
      // axios
      //   .get("/facility-categories")
      //   .then(({ data: res }) => {
      //     this.featureCategoryOptions = res.data.map(category => {
      //       return {
      //         id: category.id,
      //         name: category.name
      //       }
      //     });
      //   })
      //   .catch(err => this.$$catchErrorResponse(err));
    },

    getData() {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store
        .dispatch("featureManagement/fetchFeature", this.decryptedID)
        .then(({ data: res }) => {
          this.payload.feature_category_id = res.data.feature_category.id;
          this.payload.name = res.data.name;
          this.url = res.data.icon == null ? null : res.data.icon;
        })
        .catch(err => this.$$catchErrorResponse(err))
        .finally(res => {
          this.loading = false
          this.$vs.loading.close()
        })
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set('feature_category_id', this.payload.feature_category_id)
      formData.set("name", this.payload.name);
      formData.set("_method", "PUT");
      if (this.payload.icon !== "") {
        formData.set("icon", this.payload.icon);
      }

      this.$store
        .dispatch("featureManagement/updateFeature", {
          id: this.decryptedID,
          body: formData
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/features");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  created() {
    if (!moduleFeatureManagement.isRegistered) {
      this.$store.registerModule("featureManagement", moduleFeatureManagement);
      moduleFeatureManagement.isRegistered = true;
    }

    this.getFeatureCategories()
    this.getData()
  }
};
</script>

<style></style>
