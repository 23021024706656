import axios from '@/axios.js'

export default {
  fetchFeatures ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/facilities')
        .then(({data: response}) => {
          commit('SET_FEATURES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchFeature (context, featureId) {
    return new Promise((resolve, reject) => {
      axios.get(`/facilities/${featureId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeFeature (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/facilities', payload)
           .then(res => {
             resolve(res.data)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateFeature (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/facilities/${payload.id}`, payload.body)
           .then(res => {
             resolve(res.data)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyFeature ({ commit }, featureId) {
    return new Promise((resolve, reject) => {
      axios.delete(`feature/${featureId}`)
        .then((response) => {
          commit('REMOVE_RECORD', featureId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
