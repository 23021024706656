import state from './moduleFeatureManagementState.js'
import mutations from './moduleFeatureManagementMutations.js'
import actions from './moduleFeatureManagementActions.js'
import getters from './moduleFeatureManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

